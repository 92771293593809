<template>
    <el-card shadow="hover" :body-style="{ padding: '0 20px' }">
        <div slot="header" class="clearfix">
            <span>最近项目</span>
            <el-radio-group style="float:right" v-model="selected" size="mini" @input="reasonSelect">
                <el-radio-button :label="r" v-for="r, i in reasonData" :key="i" />
            </el-radio-group>
        </div>
        <el-table :data="tableData" stripe style="width: 100%;margin-bottom:10px" height="380px">
            <el-table-column type="index" width="55" label="序号" align="center" />
            <el-table-column label="状态" width="80" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type == '未开始'" type="danger" size="mini">{{ scope.row.type }}</el-tag>
                    <el-tag v-if="scope.row.type == '进行中'" type="warn" size="mini">{{ scope.row.type }}</el-tag>
                    <el-tag v-if="scope.row.type == '已结束'" type="success" size="mini">{{ scope.row.type }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="项目名称" width="" />
            <el-table-column prop="user" label="联系人" width="100" />
            <el-table-column prop="created" label="创建时间" width="170" />
        </el-table>
    </el-card>
</template>

<script>
export default {
    data() {
        return {
            selected: '',
            keywords: '',
            reasonData: [],
            tableData: []
        }
    },
    methods: {
        reasonSelect() {
            this.getData()
        },
        async getReason() {
            const resp = await this.$http.get('/project/types')
            this.reasonData = resp.data
            this.selected = this.reasonData[0]
            this.getData()
        },
        async getData() {
            const resp = await this.$http.get(`/project/table?page=1&pageSize=10&type=${this.selected}`)
            this.tableData = resp.data.data
        }
    },
    mounted() {
        this.getReason()
    }
}
</script>