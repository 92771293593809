<template>
    <el-card shadow="hover" :body-style="{ padding: '0 20px' }">
        <div slot="header" class="clearfix">
            <span>工作量热力图</span>
            <el-radio-group v-model="type" size="mini" style="float:right">
                <el-radio-button label="招标任务"></el-radio-button>
                <el-radio-button label="采购任务"></el-radio-button>
            </el-radio-group>
        </div>
        <div style="width:100%">
            <v-chart autoresize :option="option" style="height: 200px" v-loading="loading"></v-chart>
        </div>
    </el-card>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            type: '招标任务',
            option: {
                tooltip: {},
                visualMap: {
                    min: 0,
                    max: 100,
                    type: 'piecewise',
                    orient: 'horizontal',
                    left: 'center',
                    bottom: 15
                },
                calendar: {
                    top: 40,
                    left: 30,
                    right: 30,
                    cellSize: ['auto', 15],
                    range: '2023',
                    itemStyle: {
                        borderWidth: 0.5
                    },
                    yearLabel: { show: false }
                },
                series: {
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    data: this.getVirtualData()
                }
            }
        }
    },
    methods: {
        getVirtualData() {
            const data = [];
            let currentDate = new Date('2023-01-01')
            while (currentDate <= new Date()) {
                data.push([
                    currentDate.format('yyyy-MM-dd'),
                    Math.floor(Math.random() * 100)
                ]);
                currentDate.setDate(currentDate.getDate() + 1)
            }
            return data;
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/statistics/age_pie_chart')
            console.log(resp.data)
            this.option.series[0].data = resp.data
            this.$refs['agePieChart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        // this.getData()
    }
}
</script>
