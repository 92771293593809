<template>
    <div>
        <el-row v-loading="loading" :gutter="10">
            <el-col :span="6">
                <count-card title="专家总数" :count="100" color="rgb(128, 176, 210)" />
            </el-col>
            <el-col :span="6">
                <count-card title="任务数" :count="150" icon="el-icon-s-shop" color="rgb(141, 210, 198)" />
            </el-col>
            <el-col :span="6">
                <count-card title="已完成" :count="47" icon="el-icon-s-flag" />
            </el-col>
            <el-col :span="6">
                <count-card title="异常数据" :count="count.alert" icon="el-icon-s-opportunity" color="#bd0b0b" />
            </el-col>
        </el-row>
        <div style="margin: 20px 0">
            <el-row :gutter="10">
                <el-col :span="12">
                    <projectTable />
                </el-col>
                <el-col :span="12">
                    <projectChart />
                </el-col>
            </el-row>
        </div>
        <div>
            <calendarHeatmap />
        </div>
        <el-row :gutter="20" style="margin: 40px 0 20px">
            <el-col :span="6">
                <div>
                    <el-statistic :value="230" title="专家总数"></el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                    <el-statistic title="任务总数">
                        <template slot="formatter"> 456 </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                    <el-statistic title="抽取次数">
                        <template slot="formatter"> 456 </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                    <el-statistic title="其他">
                        <template slot="formatter"> 0 </template>
                    </el-statistic>
                </div>
            </el-col>
        </el-row>
        <div style="padding: 10px">
            <el-divider content-position="center">已经到底了</el-divider>
        </div>
    </div>
</template>

<script>
import ProjectTable from './widgets/projectTable'
import ProjectChart from './widgets/projectChart'
import CalendarHeatmap from './widgets/calendarHeatmap'
export default {
    components: {
        ProjectTable,
        ProjectChart,
        CalendarHeatmap,
    },
    data() {
        return {
            loading: false,
            count: {}
        }
    }
}
</script>
